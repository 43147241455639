import {useEffect, useRef, useState} from "react";


function App() {
    const iframeRef = useRef()
    const [detail, setDetail] = useState(null)

    const getData = async () => {
        const response = await fetch("./req.json");
        const res = await response.json();
        setDetail(res);

    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (detail && detail != null) {
            iframeRef.current.src = detail?.src;
            iframeRef.current.src = iframeRef.current.src
        }
    }, [detail])

    return (
        <div className="main" style={{height: '100svh'}}>
            <iframe ref={iframeRef} src={detail?.src}/>
        </div>
    );
}

export default App;
